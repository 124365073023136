<!--
 * @Description: 调度任务E6&G7
 * @Author: ChenXueLin
 * @Date: 2021-10-13 19:26:59
 * @LastEditTime: 2022-09-15 09:45:36
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title
        @resize="handleTableTitleResize"
        :canSetting="true"
        :tableId="tableId"
        @updateData="queryList"
      >
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="scheduleTaskId">
              <el-input
                v-model="searchForm.scheduleTaskId"
                placeholder="调度任务ID"
                title="调度任务ID"
                @input="
                  searchForm.scheduleTaskId = searchForm.scheduleTaskId.replace(
                    /[^0-9-]+/,
                    ''
                  )
                "
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="scheduleStatusList">
              <e6-vr-select
                v-model="searchForm.scheduleStatusList"
                :data="scheduleTaskStatusList"
                placeholder="调度任务状态"
                title="调度任务状态"
                clearable
                multiple
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="taskSourceList">
              <e6-vr-select
                v-model="searchForm.taskSourceList"
                :data="taskSourceList"
                placeholder="工单来源"
                title="工单来源"
                multiple
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="processor">
              <el-input
                v-model="searchForm.processor"
                placeholder="当前处理人"
                title="当前处理人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="regionalIdList">
              <e6-vr-select
                v-model="searchForm.regionalIdList"
                :data="areaList"
                :loading="selectLoading"
                placeholder="所属大区"
                title="所属大区"
                clearable
                multiple
                :props="{
                  id: 'areaId',
                  label: 'areaName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="areaIdList">
              <e6-vr-select
                v-model="searchForm.areaIdList"
                :data="cityList"
                :loading="selectLoading"
                placeholder="所属片区"
                title="所属片区"
                clearable
                multiple
                :props="{
                  id: 'areaId',
                  label: 'areaName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="addressName">
              <el-input
                v-model="searchForm.addressName"
                placeholder="所属地区"
                title="所属地区"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpId">
              <e6-vr-select
                v-model="searchForm.corpId"
                :data="corpList"
                placeholder="客户名称"
                title="客户名称"
                clearable
                virtual
                remote
                :is-title="true"
                @filterChange="handleLoadCorpFilter"
                :props="{
                  id: 'corpId',
                  label: 'corpName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="taskNumber">
              <el-input
                v-model="searchForm.taskNumber"
                placeholder="任务单号"
                title="任务单号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="taskSecondClassList">
              <e6-vr-select
                v-model="searchForm.taskSecondClassList"
                :data="taskTypeList"
                placeholder="任务单类型"
                title="任务单类型"
                :loading="selectLoading"
                multiple
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="labelTypeList">
              <e6-vr-select
                v-model="searchForm.labelTypeList"
                :data="workTagsList"
                placeholder="标签选择"
                title="标签选择"
                clearable
                multiple
                :loading="selectLoading"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item prop="companyNos">
              <e6-vr-select
                v-model="searchForm.companyNos"
                :data="serviceCompanyList"
                placeholder="签单组织"
                title="签单组织"
                clearable
                multiple
                virtual
                :is-title="true"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createByName">
              <el-input
                v-model="searchForm.createByName"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="finishTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="调度任务完结时间"
                ref="effectiveDatePicker"
                v-model="searchForm.finishTime"
                title="调度任务完结时间"
                :picker-options="pickerOptions('searchForm.finishTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="调度任务完结时间（始）"
                end-placeholder="调度任务完结时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i
            class="e6-icon-export_fill"
            title="导出"
            @click="handleExportDialog"
          ></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
        >
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            :render-header="renderHeader"
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <div
                class="table-label"
                v-if="column.fieldName === 'tagValue' && row[column.fieldName]"
              >
                <span
                  class="label-box"
                  v-for="(item, index) in row.tagValue"
                  :key="index"
                >
                  {{ item }}
                </span>
              </div>
              <span
                v-else-if="column.fieldName === 'taskStatusName'"
                :class="[
                  'status',
                  row.taskStatus == 3 || row.taskStatus == 4
                    ? 'finish'
                    : row.taskStatus === 2
                    ? 'ongoing'
                    : ''
                ]"
              >
                {{ row[column.fieldName] }}
              </span>
              <span v-else-if="column.fieldName === 'taskNumber'">
                <el-button type="text" @click="handleGoToDetail(row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 导出弹框 -->
    <export-dialog
      :exportVisible.sync="exportVisible"
      @confirm="handleExport"
      @refreshData="queryList"
      ref="exportDialog"
      :tableId="tableId"
    ></export-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import exportDialog from "@/components/exportDialog/index";
import exportData from "@/mixins/exportData";
import {
  getG7ScheduleTaskList,
  exportG7ScheduleTaskList,
  findDownList,
  getListAreaAll,
  getColumn,
  getWorkCorpList
} from "@/api";

const nowTime = new Date().getTime(); // 打开页面时的时间戳
export default {
  name: "SchedulingTasks",
  data() {
    return {
      tableId: 8010,
      searchForm: {
        scheduleTaskId: "", //调度任务ID
        taskSourceList: [], //工单来源
        processor: "", //当前处理人
        regionalIdList: [], //所属大区
        areaIdList: [], //所属片区
        addressName: "", //所属地区
        companyNos: [],
        corpId: "", //客户名称
        taskNumber: "", //任务单编号
        scheduleStatusList: [], //调度任务状态
        taskSecondClassList: [], //任务单类型
        labelTypeList: [], //标签
        payCorpCompanyNo: "",
        createByName: "", //创建人
        createTime: [nowTime - 2592000000], //创建日期
        createdTimeStart: nowTime - 2592000000, //创建开始日期 默认一个月
        createdTimeEnd: "",
        finishTime: [], //完成时间
        scheduleFinishTimeStart: "", //期待解决时间开始
        scheduleFinishTimeEnd: "", //期待解决时间结束
        pageIndex: 1,
        pageSize: 20
      },
      selectLoading: false,
      loading: false,
      serviceCompanyList: [],
      scheduleTaskStatusList: [], //调度任务状态下拉框
      taskSourceList: [], //客户来源
      areaList: [], //大区
      cityList: [], //片区
      addressList: [], //地区
      taskTypeList: [], //工单类型
      workTagsList: [], //标签
      corpList: [], //客户名称下拉框
      total: 0,
      columnData: [],
      tableData: [], // 表格数据
      exportApi: exportG7ScheduleTaskList
    };
  },
  components: { exportDialog },
  mixins: [listPage, listPageReszie, base, exportData],
  watch: {
    // 创建时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.createdTimeStart = val[0] || "";
        this.searchForm.createdTimeEnd = val[1] || "";
      }
    },
    // 完成时间
    "searchForm.finishTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.finishTime = this.searchForm.finishTime.reverse();
        }
        this.searchForm.scheduleFinishTimeStart = val[0] || "";
        this.searchForm.scheduleFinishTimeEnd = val[1] || "";
      }
    }
  },
  created() {
    this.loadCorpData();
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [
          findDownList([
            "taskSecondClass",
            "workTags",
            "scheduleTaskStatus",
            "taskSource",
            "organization"
          ]),

          getListAreaAll({ areaType: 1 }),
          getListAreaAll({ areaType: 2 })
        ];
        let [taskTypeRes, areaRes, cityRes] = await Promise.all(promiseList);
        //调度任务状态下拉框
        this.scheduleTaskStatusList = this.getFreezeResponse(taskTypeRes, {
          path: "data.scheduleTaskStatus"
        });
        //工单类型
        this.taskTypeList = this.getFreezeResponse(taskTypeRes, {
          path: "data.taskSecondClass"
        });
        this.serviceCompanyList = this.getFreezeResponse(taskTypeRes, {
          path: "data.organization"
        });
        //工单来源
        this.taskSourceList = this.getFreezeResponse(taskTypeRes, {
          path: "data.taskSource"
        });
        //标签
        this.workTagsList = this.getFreezeResponse(taskTypeRes, {
          path: "data.workTags"
        });
        //获取大区
        this.areaList = this.getFreezeResponse(areaRes, {
          path: "data"
        });
        //获取片区
        this.cityList = this.getFreezeResponse(cityRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.searchForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    //加载客户名称
    async loadCorpData(val) {
      try {
        let res = await getWorkCorpList({
          pageNum: 1,
          pageSize: 200,
          corpName: val
        });
        //获取客户名称
        this.corpList = this.getFreezeResponse(res, {
          path: "data.array"
        });
      } catch (error) {
        printError(error);
      }
    },
    //去任务单详情
    handleGoToDetail(row) {
      this.routerPush({
        name: "taskListManage/detail",
        params: {
          refresh: true,
          taskId: row.taskId
        }
      });
    },
    //查询表头
    async queryList() {
      try {
        this.loading = true;
        let res = await getColumn({
          tableId: this.tableId
        });
        this.columnData = res.data.configColumnList.filter(
          item => item.display
        );
        this.queryData();
      } catch (error) {
        printError(error);
      }
    },
    //查询全部任务单
    async queryData() {
      try {
        let res = await getG7ScheduleTaskList(this.searchForm);
        let tableData = res.data.array;
        tableData.map(item => {
          item.tagValue = item.tagValue ? item.tagValue.split(",") : [];
        });
        this.tableData = tableData;
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.routerPush({
          name: "taskListManage/schedulingTaskDetails",
          params: {
            refresh: true,
            scheduleId: row.scheduleTaskId
          }
        });
      }
    },
    toDetail() {
      this.routerPush({
        name: "taskListManage/detail",
        params: {
          refresh: true
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.table-label {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .label-box {
    padding: 0px 10px;
    border-radius: 20px;
    display: inline-block;
    border: 1px solid #46bfea;
    color: #46bfea;
    margin: 5px;
  }
}
.status {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  background: #2791f7;
  border-radius: 20px;
  border: 1px solid #2791f7;
  color: #fff;
}
.finish {
  background: #e19b39;
  border: 1px solid #e19b39;
}
.ongoing {
  background: #69c43b;
  border: 1px solid #69c43b;
}
</style>
